<template>
<div
    class="media"
    style="width: 600px"
>
    <div class="text-center point-container">
        <span
            v-if="anecdotalEvent.categoryIcon"
            style="font-size: 2rem;"
        >
            {{ anecdotalEvent.categoryIcon }}
        </span>
        <div v-if="anecdotalEvent.pointValue > 0">
            <p class="kt-font-success ">
                <span>+{{ abbrevNumber(anecdotalEvent.pointValue) }}</span>
            </p>
        </div>
        <div v-else-if="anecdotalEvent.pointValue < 0 && !anecdotalEvent.resolved">
            <p class="kt-font-danger">
                <span> {{ abbrevNumber(anecdotalEvent.pointValue) }}</span>
            </p>
        </div>
    </div>
    <div class="media-body align-self-center">
        <router-link
            :to="{
                name: 'EditAnecdotalCategory',
                params: {
                    anecdotalEventCategoryId: anecdotalEvent.anecdotalEventCategoryId
                },
            }"
            :class="`anchor-link kt-font-bold font ${anecdotalEvent.categoryColor}`"
        >
            {{ anecdotalEvent.anecdotalEventCategoryTitle }}
        </router-link>
        <span class="mx-2 kt-label-font-color-3">
            {{ lastUpdated }}
        </span>
        <span
            v-if="anecdotalEvent.isConfidential"
            class=" kt-label-font-color-4"
            style="font-size: 0.9rem;"
        >
            {{ 'N/A' }}
        </span>
        <span
            v-else
            class=" kt-label-font-color-3"
            style="font-size: 0.9rem;"
        >
            {{ anecdotalEvent.anecdotalEventDescription || '' }}
        </span>
    </div>
</div>
<!-- <div class="media mt-1 mb-1 mr-2">
            <div class="align-self-center ml-3 kt-media-group">
                <div class="kt-inbox__details">
                    <div class="kt-inbox__message">
                        <span
                            v-if="anecdotalEvent.isConfidential"
                            class="kt-inbox__summary kt-label-font-color-3"
                            style="font-size: 0.9rem;"
                        >
                            {{ 'Description omitted via share settings' }}
                        </span>
                        <span
                            v-else
                            class="kt-inbox__summary kt-label-font-color-3"
                            style="font-size: 0.9rem;"
                        >
                            {{ anecdotalEvent.anecdotalEventDescription || 'No Description' }}
                        </span>
                    </div>
                    <div
                        class="kt-inbox__labels"
                    >
                        <span
                            class="kt-label-font-color-1"
                            style="font-size: 0.9rem"
                        >{{ anecdotalEvent.author }} - {{ lastUpdated }}</span>
                    </div>
                </div>
            </div>
        </div> -->
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';

export default {
    name: 'AnecdotalRow',
    components: {

    },
    props: {
        anecdotalEvent: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            externalDistrictId: (state) => state.user.school.externalDistrictId,
        }),
        lastUpdated() {
            return moment(this.anecdotalEvent.lastUpdated).fromNow();
        },
    },
};
</script>

<style scoped>
.point-container {
    width: 80px;
    font-weight: 600;
    font-size: 1.4rem;
}
div.kt-inbox__message {
    max-height: 58px;
    overflow: clip;
}
span.kt-inbox__summary {
    display: inline;
    white-space: normal;
}
div.cell-hover {
    background-color: #f2f3f7;
    cursor: pointer;
}

.kt-inbox__subject {
    color: #48465b;
    font-weight: 600;
}

.kt-inbox__summary {
    font-size: 1rem;
    color: #a2a5b9;
    font-weight: 400;
    text-overflow: ellipsis;
}

div.student-pic {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
</style>

<style scoped>
.kt-widget--user-profile-1 span {
    line-height: 0;
}

.kt-widget__username {
    font-size: 14px !important;
}

.kt-widget__media {
    width: 25%;
    max-width: 25%;
}

</style>
