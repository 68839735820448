<template>
<div class="kt-media-group">
    <a
        v-for="(participant, idx) in visibleParticipants"
        :key="`linked_media-group_${idx}_${participant ? participant.studentEnrollmentId : 0}_${participant ? participant.schoolStaffId : 0}`"
        v-b-tooltip.hover.blur.left
        href="#"
        :title="`${participant ? participant.lastName + ', ' + participant.firstName : 'Unknown Participant'}`"
        class="kt-media kt-media--md kt-media--info"
        @click.stop.prevent="openPanelForStudent(participant)"
    >
        <span v-if="!participant">?</span>
        <template v-else>
            <UserAvatar
                v-if="participant.pictureGuid || participant.awsImageUrl"
                style="width: 50px"
                :avatar-user="participant"
            >
                <span>
                    {{ participant.lastName.substring(0, 1) }}{{ participant.firstName.substring(0, 1) }}
                </span>
            </UserAvatar>
            <span v-else>
                {{ participant.lastName.substring(0, 1) }}{{ participant.firstName.substring(0, 1) }}
            </span>
        </template>

    </a>

    <a
        v-if="maxParticipants < totalParticipants"
        href="#"
        class="kt-media kt-media--circle"
        style="background-color: rgba(15, 136, 239, 0.7);"
        @click.stop.prevent=""
    >
        <span style="color: white;">
            +{{ totalParticipants - maxParticipants }}
        </span>
    </a>
</div>
</template>

<script>

import { mapState } from 'vuex';
import UserAvatar from '../../../components/UserAvatar.vue';
import panelMixins from '../../../store/mixins/panelMixins';

export default {
    name: 'AnecdotalParticipants',
    components: {
        UserAvatar,
    },
    mixins: [panelMixins],
    props: {
        participants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            maxParticipants: 3,
        };
    },
    computed: {
        ...mapState({
            externalDistrictId: (state) => state.user.school.externalDistrictId,
        }),
        totalParticipants() {
            return (this.participants || []).length;
        },
        visibleParticipants() {
            return this.participants.slice(0, this.maxParticipants);
        },
    },
    methods: {
        openPanelForStudent(student) {
            if (!student) return;
            if (!student.studentEnrollmentId) return;
            this.$_panelMixins_openPanelForStudent({ ...student });
        },
    },
};
</script>

<style scoped>

div.kt-media-group {
    margin-left: -24px;
}
div.student-pic {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.kt-widget--user-profile-1 span {
  line-height: 0;
}

.kt-widget__username {
  font-size: 14px !important;
}

.kt-widget__media {
  width: 25%;
  max-width: 25%;
}
</style>
