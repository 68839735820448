var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-media-group" },
    [
      _vm._l(_vm.visibleParticipants, function (participant, idx) {
        return _c(
          "a",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.blur.left",
                modifiers: { hover: true, blur: true, left: true },
              },
            ],
            key: `linked_media-group_${idx}_${
              participant ? participant.studentEnrollmentId : 0
            }_${participant ? participant.schoolStaffId : 0}`,
            staticClass: "kt-media kt-media--md kt-media--info",
            attrs: {
              href: "#",
              title: `${
                participant
                  ? participant.lastName + ", " + participant.firstName
                  : "Unknown Participant"
              }`,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.openPanelForStudent(participant)
              },
            },
          },
          [
            !participant
              ? _c("span", [_vm._v("?")])
              : [
                  participant.pictureGuid || participant.awsImageUrl
                    ? _c(
                        "UserAvatar",
                        {
                          staticStyle: { width: "50px" },
                          attrs: { "avatar-user": participant },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(participant.lastName.substring(0, 1)) +
                                _vm._s(participant.firstName.substring(0, 1)) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(participant.lastName.substring(0, 1)) +
                            _vm._s(participant.firstName.substring(0, 1)) +
                            " "
                        ),
                      ]),
                ],
          ],
          2
        )
      }),
      _vm.maxParticipants < _vm.totalParticipants
        ? _c(
            "a",
            {
              staticClass: "kt-media kt-media--circle",
              staticStyle: { "background-color": "rgba(15, 136, 239, 0.7)" },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            },
            [
              _c("span", { staticStyle: { color: "white" } }, [
                _vm._v(
                  " +" +
                    _vm._s(_vm.totalParticipants - _vm.maxParticipants) +
                    " "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }