<template>
<div>
    <div
        :class="[lockByStudentId ? 'pl-0 pr-0' : '']"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <SVGIcon
                            name="search"
                            hex-color="#0f88ef"
                        />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        Anecdotal Logs
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div
                        class="btn-group btn-group-sm"
                        role="group"
                        aria-label="Small button group"
                    >
                        <button
                            type="button"
                            class="btn"
                            :class="anecdotalPointValue !== 'negatives' ? 'btn-clean btn-clean-danger' : 'btn-danger'"
                            @click.stop.prevent="anecdotalPointValue = 'negatives'"
                        >
                            Negative
                        </button>
                        <button
                            type="button"
                            class="btn"
                            :class="anecdotalPointValue !== 'neutrals' ? 'btn-clean' : 'btn-primary'"
                            @click.stop.prevent="anecdotalPointValue = 'neutrals'"
                        >
                            Neutral
                        </button>
                        <button
                            type="button"
                            class="btn"
                            :class="anecdotalPointValue !== 'positives' ? 'btn-clean btn-clean-success' : 'btn-success'"
                            @click.stop.prevent="anecdotalPointValue = 'positives'"
                        >
                            Positive
                        </button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body pt-2">
                <template v-if="!isStudentView">
                    <div class="form-group row">
                        <div class="pt-4 col-xl-3 col-md-6">
                            <label>Search Term</label>
                            <input
                                v-model="anecdotalEventDescription"
                                type="text"
                                class="form-control"
                            >
                        </div>
                        <div class="pt-4 col-xl-3 col-md-6">
                            <label class="">Category</label>
                            <AnecdotalCategoryPicker />
                        </div>
                        <div class="pt-4 col-xl-3 col-md-6">
                            <label>Staff Member</label>
                            <AnecdotalTeacherPicker />
                        </div>

                        <div class="pt-4 col-xl-3 col-md-6">
                            <label class="">Date</label>
                            <div class="kt-input-icon kt-input-icon--right">
                                <b-form-datepicker
                                    v-model="anecdotalEventDate"
                                    reset-button
                                    label-no-date-selected=""
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                                    dropbottom
                                    class=""
                                />
                                <span
                                    v-if="anecdotalEventDate"
                                    class="kt-input-icon__icon kt-input-icon__icon--right"
                                    @click="clearDate"
                                >
                                    <span><i class="la la-close" /></span>
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="$route.name !== 'StudentAnecdotals'"
                            class="pt-4 col-xl-3 col-md-6"
                        >
                            <label>Student</label>
                            <AnecdotalStudentPicker :lock-by-student-id="lockByStudentId" />
                        </div>
                    </div>

                    <div class="form-group row pb-4 d-none">
                        <div class="col-6" />
                        <div class="col-6">
                            <div class="pull-right">
                                <button
                                    class="btn btn-primary btn-sm btn-brand--icon"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': anecdotalEventSearch.loading}"
                                    @click.stop.prevent="searchAnecdotals()"
                                >
                                    <span>
                                        <i
                                            v-if="!anecdotalEventSearch.loading"
                                            class="la la-search"
                                        />
                                        <span>Search</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <div
                    v-if="anecdotals.length == 0"
                    class="kt-section"
                >
                    <div class="kt-section__info pt-5" />
                    <div class="kt-section__content">
                        <div class="alert alert-secondary fade show">
                            <div class="alert-icon">
                                <i class="flaticon-questions-circular-button" />
                            </div>
                            <div class="alert-text">
                                No anecdotal events found.
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="table-responsive pt-4"
                >
                    <table class="table table-hover">
                        <tbody>
                            <tr
                                v-for="event in anecdotals"
                                :key="event.vueId"
                                @click.stop.prevent="navigate(event.anecdotalEventId)"
                            >
                                <td class="td-col-2">
                                    <AnecdotalRow :anecdotal-event="event" />
                                </td>
                                <td
                                    v-if="!isStudentView"
                                    class="td-col-3"
                                >
                                    <AnecdotalParticipants :participants="event.participants" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                v-if="hasNextPage"
                class="kt-portlet__foot"
            >
                <div class="kt-portlet__foot-toolbar">
                    <div class="kt-portlet__foot-actions">
                        <div class="kt-pagination kt-pagination--brand kt-pagination--circle">
                            View Next {{ anecdotalEventSearch.pageSize }} Events
                            <ul class="kt-pagination__links ml-3">
                                <li class="kt-pagination__link--next">
                                    <a
                                        href="#"
                                        @click.stop.prevent="nextPage()"
                                    >
                                        <i class="fa fa-angle-right kt-font-brand" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import { mapState } from 'vuex';
import _ from 'lodash';
import AnecdotalRow from './rowComponents/AnecdotalRow.vue';
import AnecdotalParticipants from './rowComponents/AnecdotalParticipants.vue';
import AnecdotalTeacherPicker from './AnecdotalTeacherPicker.vue';
import AnecdotalStudentPicker from './AnecdotalStudentPicker.vue';
import AnecdotalCategoryPicker from './AnecdotalCategoryPicker.vue';
import Types from '../../store/Types';
import SVGIcon from '../../components/SVGIcon/SVGIcon.vue';
import userMixins from '../../store/mixins/userMixins';

export default Vue.extend({
    name: 'AnecdotalLogs',
    components: {
        AnecdotalCategoryPicker,
        AnecdotalParticipants,
        AnecdotalRow,
        AnecdotalStudentPicker,
        AnecdotalTeacherPicker,
        SVGIcon,
    },
    mixins: [
        userMixins,
    ],
    props: {
        lockByStudentId: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            showDeleted: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventSearch: (state) => state.database.anecdotalEventSearch,
            forceStudentView: (state) => state.forceStudentView,
        }),
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        role() {
            const { role } = this.$store.state.user.school;
            if (this.forceStudentView) {
                return 'Student';
            }
            return role;
        },
        hasPrevPage() {
            return this.anecdotalEventSearch.pageNumber > 1;
        },
        hasNextPage() {
            return this.anecdotals.length == this.anecdotalEventSearch.pageSize;
        },
        anecdotalEventDate: {
            get() {
                return this.anecdotalEventSearch.anecdotalEventDate;
            },
            set(value) {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE, value);
                this.searchAnecdotals();
            },
        },
        anecdotalPointValue: {
            get() {
                return this.anecdotalEventSearch.anecdotalPointValue;
            },
            set(value) {
                const { anecdotalPointValue } = this.anecdotalEventSearch;

                const newValue = value == '' ? null : value;
                if (newValue == null) {
                    this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                }
                if (newValue == 'positives') {
                    if (anecdotalPointValue == newValue) {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                    } else {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, newValue);
                    }
                }
                if (newValue == 'neutrals') {
                    if (anecdotalPointValue == newValue) {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                    } else {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, newValue);
                    }
                }
                if (newValue == 'negatives') {
                    if (anecdotalPointValue == newValue) {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                    } else {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, newValue);
                    }
                }
                this.searchAnecdotals();
            },
        },
        anecdotalEventDescription: {
            get() {
                return this.anecdotalEventSearch.anecdotalEventDescription || '';
            },
            set: _.debounce(function(value)  {  
                if (value !== this.anecdotalEventDescription) { // Prevents unnecessary triggers
                    this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION, value || '');
                    this.searchAnecdotals();
                }
            }, 500),
        },
        anecdotals() {
            const { showDeleted, isStudentView } = this;
            const { students, teachers } = this.$store.state.database;

            return this.anecdotalEvents.map((e, idx) => {
                const event = e;

                event.search = `${event.anecdotalEventDescription} ${event.anecdotalEventCategoryTitle}`.toLowerCase();
                event.vueId = `anecdotalEventId_${event.anecdotalEventId}_${idx}`;
                event.sort = `${event.anecdotalEventDate}`;

                const eventStudents = (event.students || []).map((s) => {
                    const student = students.find((x) => x.studentEnrollmentId == s.studentEnrollmentId);
                    if (!student) return null;
                    const item = { ...student };
                    item.search = `${event.search} ${student.lastName} ${student.firstName}`.toLowerCase();
                    return item;
                }).filter((x) => Boolean(x));

                const eventTeachers = (event.teachers || []).map((s) => {
                    const teacher = teachers.find((x) => x.schoolStaffId == s.schoolStaffId);
                    if (!teacher) return null;
                    const item = { ...teacher };
                    item.search = `${event.search} ${teacher.lastName} ${teacher.firstName}`.toLowerCase();
                    return item;
                }).filter((x) => Boolean(x));

                event.participants = [...eventStudents, ...eventTeachers];

                return event;
            }).filter((e) => {
                if (showDeleted && !isStudentView) return e.deleted;
                return !e.deleted;
            });
        },
        ...mapState({
            user: (state) => state.user,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
            students: (state) => state.database.students,
        }),
    },
    watch: {
        forceStudentView() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            if (this.lockByStudentId) {
                this.$store.commit(Types.mutations.SET_DB_ANECDOTAL_EVENTS, []); // clear cached
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, this.lockByStudentId);
            } else {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, null);
            }
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        clearDate() {
            this.anecdotalEventDate = null;
        },
        download() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL, true);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        reset() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, null);
        },
        newAnecdotal() {
            if (this.lockByStudentId) {
                const student = this.students.find((x) => x.studentId == this.lockByStudentId);
                if (!student) return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
                return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
        prevPage() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_PAGE_NUMBER, this.anecdotalEventSearch.pageNumber - 1);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        nextPage() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_PAGE_NUMBER, this.anecdotalEventSearch.pageNumber + 1);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        searchAnecdotals() {
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        navigate(anecdotalEventId) {
            if (this.$route.name == 'StudentPortfolio') {
                const params = { ...this.$route.params, ...{ detailId: anecdotalEventId } };
                this.$router.push({ name: 'StudentPortfolio', params });
            } else {
                this.$router.push({ name: 'ViewAnecdotal', params: { anecdotalEventId } });
            }
        },
    },
});
</script>

<style scoped>

div.no-wrap {
    white-space: nowrap;
}
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid #ebedf2;
}

tr td {
    cursor: pointer;
}

td.td-col-1 {
    vertical-align: middle;
    width: 72px;
}

td.td-col-2 {
    width: 600px;
}

td.td-col-3 {
    vertical-align: middle;
    width: 140px;
}

td.td-col-4 {
    vertical-align: middle;
    width: 300px;
}
.kt-widget__media {
    margin-left: 40px;
}
.kt-media-group .kt-media {
    margin-left: -22px;
}
.kt-media-group .kt-media:first-child {
    margin-left: 40px;
}
</style>
