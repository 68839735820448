<template>
<div>
    <div class="kt-input-icon">
        <input
            v-model="searchTerm"
            type="text"
            :disabled="lockByStudentId"
            style="background: transparent !important;"
            class="form-control pl-5"
            @blur="blurEventHandler($event)"
            @focus="focusEventHandler($event)"
            @keyup.13="selectPortfolio(0)"
            @keydown="showPicker = true"
        >
        <span class="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
                <i class="la la-search" />
            </span>
        </span>

        <span
            v-if="selectedStudent && !onSelected && !lockByStudentId"
            class="kt-input-icon__icon kt-input-icon__icon--right"
            @click.stop.prevent="clearStudent"
        >
            <span>
                <i class="la la-close" />
            </span>
        </span>
    </div>

    <div
        v-if="students.length > 0 && showPicker"
        class="kt-widget4 p-3 mt-2"
    >
        <div
            v-for="(student, idx) in students"
            :key="`${idx}_portfolio`"
            class="kt-widget4__item"
            @click.stop.prevent="selectPortfolio(idx)"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <UserAvatar :avatar-user="student">
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ student.lastName.substring(0,1) }}{{ student.firstName.substring(0,1) }}
                    </div>
                </UserAvatar>
            </div>

            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent="selectPortfolio(idx)"
                >
                    {{ student.lastName }}, {{ student.firstName }}
                </a>
                <p class="kt-widget4__text">
                    {{ student.extStudentId.substring(0, 3) }}-{{
                        student.extStudentId.substring(3, 6)
                    }}-{{ student.extStudentId.substring(6, 9) }}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import UserAvatar from '../../components/UserAvatar.vue';
import Types from '../../store/Types';

export default {
    name: 'AnecdotalStudentPicker',
    components: {
        UserAvatar,
    },
    props: {
        onSelected: {
            type: Function,
            required: false,
            default: null,
        },
        lockByStudentId: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            searchTerm: '',
            selectedStudent: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        students() {
            const { students } = this.$store.state.database;
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            const { lockByStudentId } = this;
            const output = students.filter((t) => {
                const search = `${t.lastName}, ${t.firstName} ${t.extStudentId || ''} ${t.googleEmail || ''} ${t.schoolEmail || ''}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            }).filter((t) => {
                if (!lockByStudentId) return true;
                return t.studentId == lockByStudentId;
            });
            return output.slice(0, 3);
        },
    },
    mounted() {
        if (this.lockByStudentId) {
            if (this.students.length == 1) {
                this.selectPortfolio(0);
            }
        }
    },
    methods: {
        clearStudent() {
            this.selectedStudent = null;
            this.searchTerm = '';
            if (!this.onSelected) {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, null);
                this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
            }
        },
        blurEventHandler() {
            const v = this;
            v.timeout = setTimeout(() => {
                v.showPicker = false;
            }, 600);
        },
        focusEventHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.showPicker = true;
        },
        selectPortfolio(idx) {
            this.selectedStudent = this.students[idx];
            this.showPicker = false;
            if (this.onSelected) {
                this.searchTerm = '';
                this.onSelected(this.selectedStudent);
                return;
            }
            this.searchTerm = `${this.selectedStudent.lastName}, ${this.selectedStudent.firstName}`;
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, this.selectedStudent.studentId);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
};
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 75%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
}
div.kt-widget4__info {
    overflow-x: clip;
    width: 80%;
}
</style>
