var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media", staticStyle: { width: "600px" } }, [
    _c("div", { staticClass: "text-center point-container" }, [
      _vm.anecdotalEvent.categoryIcon
        ? _c("span", { staticStyle: { "font-size": "2rem" } }, [
            _vm._v(" " + _vm._s(_vm.anecdotalEvent.categoryIcon) + " "),
          ])
        : _vm._e(),
      _vm.anecdotalEvent.pointValue > 0
        ? _c("div", [
            _c("p", { staticClass: "kt-font-success" }, [
              _c("span", [
                _vm._v(
                  "+" + _vm._s(_vm.abbrevNumber(_vm.anecdotalEvent.pointValue))
                ),
              ]),
            ]),
          ])
        : _vm.anecdotalEvent.pointValue < 0 && !_vm.anecdotalEvent.resolved
        ? _c("div", [
            _c("p", { staticClass: "kt-font-danger" }, [
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.abbrevNumber(_vm.anecdotalEvent.pointValue))
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "media-body align-self-center" },
      [
        _c(
          "router-link",
          {
            class: `anchor-link kt-font-bold font ${_vm.anecdotalEvent.categoryColor}`,
            attrs: {
              to: {
                name: "EditAnecdotalCategory",
                params: {
                  anecdotalEventCategoryId:
                    _vm.anecdotalEvent.anecdotalEventCategoryId,
                },
              },
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.anecdotalEvent.anecdotalEventCategoryTitle) + " "
            ),
          ]
        ),
        _c("span", { staticClass: "mx-2 kt-label-font-color-3" }, [
          _vm._v(" " + _vm._s(_vm.lastUpdated) + " "),
        ]),
        _vm.anecdotalEvent.isConfidential
          ? _c(
              "span",
              {
                staticClass: "kt-label-font-color-4",
                staticStyle: { "font-size": "0.9rem" },
              },
              [_vm._v(" " + _vm._s("N/A") + " ")]
            )
          : _c(
              "span",
              {
                staticClass: "kt-label-font-color-3",
                staticStyle: { "font-size": "0.9rem" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.anecdotalEvent.anecdotalEventDescription || "") +
                    " "
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }