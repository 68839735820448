import { render, staticRenderFns } from "./AnecdotalStudentPicker.vue?vue&type=template&id=e57a64f2&scoped=true"
import script from "./AnecdotalStudentPicker.vue?vue&type=script&lang=ts"
export * from "./AnecdotalStudentPicker.vue?vue&type=script&lang=ts"
import style0 from "./AnecdotalStudentPicker.vue?vue&type=style&index=0&id=e57a64f2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e57a64f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e57a64f2')) {
      api.createRecord('e57a64f2', component.options)
    } else {
      api.reload('e57a64f2', component.options)
    }
    module.hot.accept("./AnecdotalStudentPicker.vue?vue&type=template&id=e57a64f2&scoped=true", function () {
      api.rerender('e57a64f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/anecdotals/AnecdotalStudentPicker.vue"
export default component.exports